<template>
    <v-container>
        <DlgMessage ref="dlgMsg" @dlgClose="dlgClose"></DlgMessage>
        <div class="mb-3">
          <v-toolbar>
            <v-btn icon class="hidden-xs-only" @click="$router.back()"><v-icon>mdi-arrow-left</v-icon></v-btn>
            <v-toolbar-title>{{ this.routeParam.ns_name }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-breadcrumbs :items="items"></v-breadcrumbs>
          </v-toolbar>
        </div> 
        <v-row class="mt-3">
           <v-col cols="12" md="12">
                <v-toolbar dark color="panel1"><v-toolbar-title>Info</v-toolbar-title>
                    <v-spacer></v-spacer>
                <v-btn icon dark @click="save"><v-icon>save</v-icon></v-btn>
            </v-toolbar>             
                <v-card class="mt-5">
                <v-form ref="mainForm">
                    <v-container class="py-0">
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field v-model="form.notification_setting_id" disabled label="Page Rec Id" outlined></v-text-field>                                                                
                                <v-text-field v-model="form.ns_code" label="Code" :rules="inputRules" outlined></v-text-field>
                                <v-text-field v-model="form.ns_name" label="Name" :rules="inputRules" outlined></v-text-field>
                                <v-text-field v-model="form.topic" label="FCM Topic" :rules="inputRules" outlined></v-text-field>
                                <v-text-field v-model="form.sender_id" label="Sender ID" outlined></v-text-field>
                                <v-text-field v-model="form.url" label="URL" outlined></v-text-field>
                                <v-textarea v-model="form.server_key" label="Api-Key" outlined></v-textarea>
                                <v-select
                                    v-model = "form.status"
                                    :items="status_list"
                                    item-text="description"
                                    item-value="code"
                                    label="Status"
                                    standard
                                    :rules="inputRules"
                                    outlined
                                ></v-select>
                                
                            </v-col>
                        </v-row>

                    </v-container>
                </v-form>

                </v-card>

            </v-col>
            <!--image-->
            <v-col cols="12" md="12">
                <v-toolbar dark color="panelSysInfo"><v-toolbar-title>System Info</v-toolbar-title>
                </v-toolbar>             
                <v-card class="mt-5">
                    <v-container class="py-0">
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field v-for="info in systemInfo" :key="info.alias" :value="form[info.name]" disabled :label="info.alias" outlined></v-text-field>
                            </v-col>
                        </v-row>

                    </v-container>

                </v-card>

                
            </v-col>

        </v-row>

    </v-container>  
</template>
    
<script>
import DlgMessage from '@/components/DlgMessage'
import ApNotificationService from "@/services/ApNotificationService"

export default {
  components:{DlgMessage},
  props: {
       caller: String
    },
  data() {
    return{
            //breadcrumb
            items: [
              {text:'Notification',disabled: false,href:'/notification/',},
              {text:'Setting',disabled: false,href:'/Notification/setttings',},
              {text:'Details',disabled: false,href:'#',},
              ],
            status_list:[],
            routeParam:[],
            form:{login_id:null,notification_setting_id:0},
            systemInfo:[
                {"name":"created_date","alias":"Created Date"},
                {"name":"created_by","alias":"Created By"},
                {"name":"created_at","alias":"Created At"},
                {"name":"updated_date","alias":"Updated Date"},
                {"name":"updated_by","alias":"Updated By"},
                {"name":"updated_at","alias":"Updated At"},
            ],

            logo:'',

            inputRules: [
             v => !!v || 'This field is required'
            ],

            cpFiles:{
                file_type:'image',
                route_type:'merchants',
                showDialog:false,
            },
            dpicker:{menu1: false,menu2: false,},

    }
  },
  async created() {
    this.routeParam['notification_setting_id'] = this.$route.params.notification_setting_id;
    this.routeParam['ns_name'] = atob(this.$route.params.enc_ns_name);
    this.viewDetail();

  },
  
  methods:{

    async viewDetail(){
        this.form.notification_setting_id = this.routeParam.notification_setting_id
        try{
            ApNotificationService.view_notification_setting(this.form).then((res) => {
                this.form = res.data.data;
                for (const [key, value] of Object.entries(this.form)) {
                    if(value == null){
                        this.form[key] = "";
                    }
                }                
                console.log(res.data);
                this.status_list = res.data.statusList; 
               
            }).catch((e)=>{
                console.log(e.response);
                this.$refs.dlgMsg.showDlgBox(true,'error','View Notification Settings',e.response.data.error_message);
            })
            ;
        } catch(e) {
            console.log(e);
            //this.$refs.dlgMsg.showDlgBox(true,'error','Supplier','Unknown Error');
        }
    },
    async save(){
        try{
            ApNotificationService.update_notification_setting(this.form).then(() => {
                this.$refs.dlgMsg.showDlgBox(true,'success','Update Notification Setting','Success.');
            }).catch((e)=>{
                console.log(e.response);
                this.$refs.dlgMsg.showDlgBox(true,'error','Update Notification Setting',e.response.data.error_message);
            })
            ;
        } catch(e) {
            console.log(e);
            //this.$refs.dlgMsg.showDlgBox(true,'error','Supplier','Unknown Error');
        }
    },
    
    dlgClose(){
        this.visible = false;        
    },
  }
}
</script>